import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin-top: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n\n  .swiper {\n    margin-left: inherit;\n  }\n\n  .swiper-wrapper {\n    height: inherit;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 45px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 120px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  color: ",
        ";\n\n  img {\n    width: 18px;\n  }\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    padding: 30px !important;\n\n    p {\n      margin-block: 0;\n    }\n\n    @media (min-width: ",
        ") {\n      padding: 45px 30px 48px !important;\n    }\n\n    @media (min-width: ",
        ") {\n      padding: 48px 48px 32px 48px !important;\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-b147c920-0"
})(_templateObject());
export var TabsContainer = styled.div.withConfig({
    componentId: "sc-b147c920-1"
})(_templateObject1(), function(param) {
    var enablePaddingTop = param.enablePaddingTop;
    return enablePaddingTop ? 0 : "48px";
});
export var ProductListContainer = styled.div.withConfig({
    componentId: "sc-b147c920-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.bigDesktop);
export var DisclaimerContainer = styled.div.withConfig({
    componentId: "sc-b147c920-3"
})(_templateObject3(), fonts.regular, colors.$7e7e7e);
export var Title = styled.div.withConfig({
    componentId: "sc-b147c920-4"
})(_templateObject4(), breakpoints.tablet, breakpoints.desktop);
