import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { nextClient, handleUseQuery, LoggerInstance } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { errorMock, ErrorCodes, checkBypassApiCallFromSIA } from "@vfit/consumer/data-access";
/**
 * This method is used run the billing account service from the customerId.
 * Method: GET
 * SearchParams: salesChannel: selfService, checkExistingInstallment: true
 * @param customerId
 * @param customOptions
 * @returns
 */ var billingAccountService = function(customerId, customOptions) {
    return nextClient.get("".concat(API.CUSTOMER, "/").concat(customerId, "/").concat(API.BILLING_ACCOUNT), _object_spread({
        searchParams: {
            salesChannel: "selfService",
            checkExistingInstallment: true
        }
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the billingAccountService
 * @param customOptions
 * @returns
 */ export var useBillingAccount = function(customOptions) {
    var ref, ref1;
    var customerData = getFromLocalStorageByKey("customerData");
    var findCaller = getFromLocalStorageByKey("findCaller");
    var customerId = (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id) || (findCaller === null || findCaller === void 0 ? void 0 : (ref1 = findCaller.customerRef) === null || ref1 === void 0 ? void 0 : ref1.id);
    var queryString = window.location.search;
    var customerQueryEnabler = !!customerId && !(queryString.includes(ErrorCodes.ERROR_ON_PAYMENT) || queryString.includes(ErrorCodes.MODE_BACK_SIA) || checkBypassApiCallFromSIA());
    var queryEnabler = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions.enabled : customerQueryEnabler;
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "billingAccount";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return billingAccountService(customerId, customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: queryEnabler && ((customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled : true),
            onSuccess: function(data) {
                var ref;
                localStorage.setItem("billingAccount", JSON.stringify(data));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
            },
            onError: function(error) {
                var ref;
                LoggerInstance.error("ERROR - billingAccount: ", error);
                localStorage.setItem("billingAccount", JSON.stringify(errorMock("billingAccount", error)));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
            }
        })
    };
    return handleUseQuery(options);
};
