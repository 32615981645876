import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-business-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { errorMock } from "@vfit/consumer/data-access";
import { adaptSelectedValue, retrieveMSDIN, retrieveProvider } from "./portabilityNumber.utils";
/**
 * API service to validate the portability number process
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */ export var modifyPhoneNumberProductService = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(cartId, payload, customOptions) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", nextClient.post("".concat(API.SHOPPING_CART, "/").concat(cartId, "/").concat(API.MODIFY_PHONE_NUMBER_PRODUCT), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
                        headers: customOptions.headers
                    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
                        silentLoginHeaders: customOptions.silentLoginHeaders
                    })));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function modifyPhoneNumberProductService(cartId, payload, customOptions) {
        return _ref.apply(this, arguments);
    };
}();
/**
 * API service to validate the portability number mobile process
 * @param cartId
 * @param payload
 * @returns
 */ export var modifyPhoneNumberMobileProductService = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(cartId, payload) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", nextClient.post("".concat(API.SHOPPING_CART, "/").concat(cartId, "/").concat(API.MODIFY_PHONE_NUMBER_PRODUCT), payload));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function modifyPhoneNumberMobileProductService(cartId, payload) {
        return _ref.apply(this, arguments);
    };
}();
/**
 * Mutation for saving into the key 'modifyPhoneNumberProductQuery'
 * the response coming from the API service modifyPhoneNumberProduct
 * to validate the portability number process
 * @returns
 * @deprecated
 */ export var useModifyPhoneNumberProduct = function() {
    var queryClient = useQueryClient();
    return useMutation("modifyPhoneNumberProductMutation", function(data) {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6;
        var values = data.values, providers = data.providers, isMultipleOperator = data.isMultipleOperator;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var offeringServiceability = getFromLocalStorageByKey("offeringServiceability");
        var multiPlayOfferingConfiguration = getFromLocalStorageByKey("multiPlayOfferingConfiguration");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var firstServiceProviders = !!multiPlayOfferingConfiguration && (multiPlayOfferingConfiguration === null || multiPlayOfferingConfiguration === void 0 ? void 0 : multiPlayOfferingConfiguration.fixedVoicePortInDetails[0].r1MissingMigrationFixedPortInSpecification.r1FirstDonatingNetworkOperator.availableValues);
        var secondServiceProviders = !!multiPlayOfferingConfiguration && (multiPlayOfferingConfiguration === null || multiPlayOfferingConfiguration === void 0 ? void 0 : multiPlayOfferingConfiguration.fixedVoicePortInDetails[0].r1MissingMigrationFixedPortInSpecification.r1SecondDonatingNetworkOperator.availableValues); // .filter((item:any)=> {item})//.value === 'TLC'})
        var firstProvider = firstServiceProviders && retrieveProvider(firstServiceProviders, providers.firstSelectedProvider);
        var secondProvider = secondServiceProviders && retrieveProvider(secondServiceProviders, providers.secondSelectedProvider);
        // const isMultipleOperator = values?.portabilityOperators || false;
        var productCharacteristic = shoppingCart && ((ref1 = (ref = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.cartItem[0]) === null || ref === void 0 ? void 0 : ref.fixedVoice[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.product) === null || ref2 === void 0 ? void 0 : ref2.productCharacteristic);
        var defMSDIN = productCharacteristic && retrieveMSDIN(productCharacteristic);
        var payload = {
            fixedPortInSpecification: {
                portIndicator: true
            },
            internetPortInSpecification: {
                portIndicator: false
            },
            r1FixedPortInSpecification: _object_spread({
                r1PhoneNumber: values === null || values === void 0 ? void 0 : values.cli,
                r1IsInternetIndicator: values === null || values === void 0 ? void 0 : values.hasInternetService,
                r1FirstDonatingNetworkOperator: {
                    selectedValue: firstProvider
                }
            }, (values === null || values === void 0 ? void 0 : values.firstMigrationCode) && {
                r1FirstDonatingNetworkOperatorCode: {
                    selectedValue: values === null || values === void 0 ? void 0 : values.firstMigrationCode
                }
            }, (providers === null || providers === void 0 ? void 0 : providers.secondSelectedProvider) && _object_spread({
                r1SecondDonatingNetworkOperator: {
                    selectedValue: secondProvider
                }
            }, (values === null || values === void 0 ? void 0 : values.firstMigrationCode) && {
                r1SecondDonatingNetworkOperatorCode: {
                    selectedValue: isMultipleOperator ? values === null || values === void 0 ? void 0 : values.secondMigrationCode : values === null || values === void 0 ? void 0 : values.firstMigrationCode
                }
            })),
            serviceabilityId: offeringServiceability === null || offeringServiceability === void 0 ? void 0 : offeringServiceability.id,
            fixedCartItemId: (ref4 = (ref3 = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.cartItem[0]) === null || ref3 === void 0 ? void 0 : ref3.fixedVoice[0]) === null || ref4 === void 0 ? void 0 : ref4.id,
            internetCartItemId: (ref6 = (ref5 = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.cartItem[0]) === null || ref5 === void 0 ? void 0 : ref5.internet[0]) === null || ref6 === void 0 ? void 0 : ref6.id,
            defaultMsisdn: defMSDIN
        };
        return modifyPhoneNumberProductService(cartId, payload);
    }, {
        onSuccess: function(data) {
            // save modifyPhoneNumberProductQuery object into storage
            localStorage.setItem("modifyPhoneNumberProductQuery", JSON.stringify(data));
            queryClient.setQueryData("modifyPhoneNumberProductQuery", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - modifyPhoneNumberProductQuery: ", error);
            queryClient.setQueryData("modifyPhoneNumberProductQuery", errorMock("modifyPhoneNumberProductQuery", error, "KO"));
            localStorage.setItem("modifyPhoneNumberProductQuery", JSON.stringify(errorMock("modifyPhoneNumberProductQuery", error, "KO")));
        }
    });
};
export var useModifyPhoneNumberFixedProduct = function() {
    var queryClient = useQueryClient();
    return useMutation("modifyPhoneNumberProductMutation", function(param) {
        var hasInternetService = param.hasInternetService, isMultipleOperator = param.isMultipleOperator, portabilityNumber = param.portabilityNumber, portabilityMigration = param.portabilityMigration;
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var offeringServiceability = getFromLocalStorageByKey("offeringServiceability");
        var multiPlayOfferingConfiguration = getFromLocalStorageByKey("multiPlayOfferingConfiguration");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var firstServiceProviders = multiPlayOfferingConfiguration === null || multiPlayOfferingConfiguration === void 0 ? void 0 : (ref = multiPlayOfferingConfiguration.fixedVoicePortInDetails) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.r1MissingMigrationFixedPortInSpecification) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.r1FirstDonatingNetworkOperator) === null || ref3 === void 0 ? void 0 : ref3.availableValues;
        var secondServiceProviders = multiPlayOfferingConfiguration === null || multiPlayOfferingConfiguration === void 0 ? void 0 : (ref4 = multiPlayOfferingConfiguration.fixedVoicePortInDetails) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.r1MissingMigrationFixedPortInSpecification) === null || ref6 === void 0 ? void 0 : ref6.r1SecondDonatingNetworkOperator.availableValues;
        var productCharacteristic = (ref8 = (ref7 = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.cartItem[0]) === null || ref7 === void 0 ? void 0 : ref7.fixedVoice[0]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.product) === null || ref9 === void 0 ? void 0 : ref9.productCharacteristic;
        var defMSDIN = productCharacteristic && retrieveMSDIN(productCharacteristic);
        // const isMultipleOperator =
        //   multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]?.r1MissingMigrationFixedPortInSpecification?.r1VoiceAndInternetDifferentProvider?.toLowerCase() ===
        //     'yes' || false;
        // const hasInternetService =
        //   multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]
        //     ?.r1MissingMigrationFixedPortInSpecification?.r1IsInternetIndicator === 'Yes';
        var providers = _object_spread({
            firstSelectedProvider: portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.firstOperator
        }, hasInternetService && isMultipleOperator ? {
            secondSelectedProvider: portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.secondOperator
        } : hasInternetService && !isMultipleOperator ? {
            secondSelectedProvider: portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.firstOperator
        } : {});
        var firstProvider = firstServiceProviders && retrieveProvider(firstServiceProviders, providers.firstSelectedProvider);
        var secondProvider = secondServiceProviders && retrieveProvider(secondServiceProviders, providers.secondSelectedProvider);
        var payload = {
            fixedPortInSpecification: {
                portIndicator: true
            },
            internetPortInSpecification: {
                portIndicator: false
            },
            r1FixedPortInSpecification: _object_spread({
                r1PhoneNumber: portabilityNumber,
                r1IsInternetIndicator: hasInternetService ? "Yes" : "No",
                r1FirstDonatingNetworkOperator: {
                    selectedValue: firstProvider
                }
            }, (portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.firstCode) && {
                r1FirstDonatingNetworkOperatorCode: {
                    selectedValue: portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.firstCode
                }
            }, (providers === null || providers === void 0 ? void 0 : providers.secondSelectedProvider) && _object_spread({
                r1SecondDonatingNetworkOperator: {
                    selectedValue: secondProvider
                }
            }, (portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.firstCode) && {
                r1SecondDonatingNetworkOperatorCode: {
                    selectedValue: isMultipleOperator ? portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.secondCode : portabilityMigration === null || portabilityMigration === void 0 ? void 0 : portabilityMigration.firstCode
                }
            })),
            serviceabilityId: offeringServiceability === null || offeringServiceability === void 0 ? void 0 : offeringServiceability.id,
            fixedCartItemId: (ref11 = (ref10 = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.cartItem[0]) === null || ref10 === void 0 ? void 0 : ref10.fixedVoice[0]) === null || ref11 === void 0 ? void 0 : ref11.id,
            internetCartItemId: (ref13 = (ref12 = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.cartItem[0]) === null || ref12 === void 0 ? void 0 : ref12.internet[0]) === null || ref13 === void 0 ? void 0 : ref13.id,
            defaultMsisdn: defMSDIN
        };
        return modifyPhoneNumberProductService(cartId, payload);
    }, {
        onSuccess: function(data) {
            // save modifyPhoneNumberProductQuery object into storage
            localStorage.setItem("modifyPhoneNumberProductQuery", JSON.stringify(data));
            queryClient.setQueryData("modifyPhoneNumberProductQuery", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - modifyPhoneNumberProductQuery: ", error);
            queryClient.setQueryData("modifyPhoneNumberProductQuery", errorMock("modifyPhoneNumberProductQuery"));
            localStorage.setItem("modifyPhoneNumberProductQuery", JSON.stringify(errorMock("modifyPhoneNumberProductQuery")));
        }
    });
};
export var useModifyPhoneNumberMobileProduct = function() {
    var queryClient = useQueryClient();
    return useMutation("modifyPhoneNumberProductMutation", function(param) {
        var msisdn = param.msisdn, isSimAvailable = param.isSimAvailable, simCardNumber = param.simCardNumber, currentPlanType = param.currentPlanType, currentServiceProviderId = param.currentServiceProviderId, transferRemainingBalance = param.transferRemainingBalance;
        var ref, ref1;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var payload = {
            phoneNumberProduct: {
                isPortIn: true,
                msisdn: msisdn,
                // "simAvailable" should not be passed for 'modifyPhoneNumberProduct'
                // simAvailable: {
                //   selectedValue: adaptSelectedValue(isSimAvailable),
                // },
                transferRemainingBalance: {
                    selectedValue: adaptSelectedValue(transferRemainingBalance)
                },
                portInProviderCust: {
                    selectedValue: currentServiceProviderId
                },
                currentPlan: {
                    selectedValue: currentPlanType
                }
            },
            mobileCartitemId: shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref = shoppingCart.cartItem) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.id,
            levelOfData: "minimalData"
        };
        var isSimAvailableBoolean = JSON.parse(isSimAvailable);
        if (isSimAvailableBoolean) {
            payload = _object_spread_props(_object_spread({}, payload), {
                phoneNumberProduct: _object_spread_props(_object_spread({}, payload.phoneNumberProduct), {
                    simNumber: simCardNumber
                })
            });
        }
        return modifyPhoneNumberMobileProductService(cartId, payload);
    }, {
        onSuccess: function() {
            LoggerInstance.error("SUCCESS - modifyPhoneNumberMobileProductQuery: ");
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - modifyPhoneNumberMobileProductQuery: ", error);
            queryClient.setQueryData("modifyPhoneNumberProductQuery", errorMock("modifyPhoneNumberProductQuery"));
            localStorage.setItem("modifyPhoneNumberProductQuery", JSON.stringify(errorMock("modifyPhoneNumberProductQuery")));
        }
    });
};
