import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { getProductsSlider, IWidgetType } from "@vfit/consumer/data-access";
var getProductSlidesConsumer = function(widget, panicModeConfiguration, globalPromo) {
    var ref = getProductsSlider(widget, (widget === null || widget === void 0 ? void 0 : widget.title) || "", panicModeConfiguration, globalPromo), slides = ref.slides, text = ref.text;
    return {
        slides: slides.filter(function(s) {
            return s.product !== null;
        }),
        topText: text
    };
};
/**
 * Method to return the faq widget
 * @param widget
 */ var getFaqWidget = function(widget) {
    return {
        topText: (widget === null || widget === void 0 ? void 0 : widget.title) || "",
        faq: {
            divider: (widget === null || widget === void 0 ? void 0 : widget.divider) || undefined,
            elements: (widget === null || widget === void 0 ? void 0 : widget.elements) || [],
            showMore: (widget === null || widget === void 0 ? void 0 : widget.customAction) || undefined
        }
    };
};
/**
 * Add in documentation only for splitted images text widget
 * @param widget
 */ var getSplittedImageTextWidget = function(widget) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
    return {
        topText: (widget === null || widget === void 0 ? void 0 : widget.title) || "",
        title: (widget === null || widget === void 0 ? void 0 : (ref = widget.elements) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        description: (widget === null || widget === void 0 ? void 0 : (ref2 = widget.elements) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        urlImg: (widget === null || widget === void 0 ? void 0 : (ref4 = widget.elements) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : ref5.image) || "",
        urlImgMobile: (widget === null || widget === void 0 ? void 0 : (ref6 = widget.elements) === null || ref6 === void 0 ? void 0 : (ref7 = ref6[0]) === null || ref7 === void 0 ? void 0 : ref7.imageMobile) || "",
        info: (widget === null || widget === void 0 ? void 0 : widget.description) || "",
        action: widget === null || widget === void 0 ? void 0 : widget.customAction,
        disableCoverImg: (widget === null || widget === void 0 ? void 0 : widget.isShort) === "true",
        divider: widget === null || widget === void 0 ? void 0 : widget.divider
    };
};
/**
 * Method to organize all widgets
 * Include filters for all widgets tabs
 * @param widgets
 */ var organizeWidgets = function(widgets) {
    var haveTabsWidgets = widgets.some(function(widget) {
        return widget.type === IWidgetType.TABS_WIDGETS;
    });
    if (!haveTabsWidgets) {
        return widgets;
    }
    // TODO: Remove button?localUrl after CMS release
    var widgetsWithoutRef = widgets.filter(function(widget) {
        var ref;
        return !widget.ref && !(widget === null || widget === void 0 ? void 0 : (ref = widget.button) === null || ref === void 0 ? void 0 : ref.localUrl);
    });
    var widgetsWithRef = widgets.filter(function(widget) {
        var ref;
        return !!widget.ref || !!(widget === null || widget === void 0 ? void 0 : (ref = widget.button) === null || ref === void 0 ? void 0 : ref.localUrl);
    });
    return widgetsWithoutRef.map(function(widget) {
        if (widget.type !== IWidgetType.TABS_WIDGETS) return widget;
        return _object_spread_props(_object_spread({}, widget), {
            elements: widget.elements.map(function(element) {
                return _object_spread_props(_object_spread({}, element), {
                    widgets: widgetsWithRef.filter(function(wWR) {
                        var ref, ref1, ref2;
                        return wWR.ref == (element === null || element === void 0 ? void 0 : (ref = element.id) === null || ref === void 0 ? void 0 : ref.toString()) || ((ref1 = wWR.button) === null || ref1 === void 0 ? void 0 : ref1.localUrl) == (element === null || element === void 0 ? void 0 : (ref2 = element.id) === null || ref2 === void 0 ? void 0 : ref2.toString());
                    })
                });
            })
        });
    });
};
export { getProductSlidesConsumer, getFaqWidget, getSplittedImageTextWidget, organizeWidgets };
